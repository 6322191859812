import './App.css';

function App() {
  return (
    <div className='md:container md:mx-auto mt-52 justify-center flex'>
      <div className='w-4/12 pt-4 pl-4 pr-4 shadow-md rounded border relative'>
        <div className='rounded-b' style={{width:'100%',height:400,backgroundColor:'#ececec'}}>
          testing master again313
        </div>
        <div className="grid grid-rows-3 grid-flow-col gap-4 items-end mt-10 mb-10">
          <div className="row-span-full">upload</div>
          <div className="row-span-full">save</div>
        </div>
      </div>
    </div>
  );
}

export default App;
